/* eslint import/no-anonymous-default-export: [2, {"allowObject": true}] */
export default {
  //AUTH
  USER_REGISTERED: 'USER_REGISTERED',
  USER_LOGGEDIN: 'USER_LOGGEDIN',
  USER_REGISTERED_AND_LOGGED_IN: 'USER_REGISTERED_AND_LOGGED_IN',
  USER_AUTH_ERROR: 'USER_AUTH_ERROR',
  GET_CURRENT_USER: 'GET_CURRENT_USER',
  USER_LOGOUT: 'USER_LOGOUT',
  //USERS
  GET_USERS: 'GET_USERS',
  UPDATE_USER: 'UPDATE_USER',
  UPDATE_USER_SETTINGS: 'UPDATE_USER_SETTINGS',
  UPDATE_RESIDENT_INFO: 'UPDATE_RESIDENT_INFO',
  UPDATE_USER_PASSWORD: 'UPDATE_USER_PASSWORD',
};
