import React from 'react';

function ZoomConfig(props) {
  const [token, setToken] = React.useState({});

  React.useEffect(() => {
    const windowUrl = window.location.search;
    const params = new URLSearchParams(windowUrl);
    setToken({code: params.get('code')})
  }, []);

  React.useEffect(() => {
    // Redirect with token
    if (token.code && token.code.length > 0) {
      document.location.href = `https://keentek.app?code=${token.code}`;
    }
  }, [token.code]);

  return (
    <div>
      Redirecting...
    </div>
  );
}

export default ZoomConfig;
