import React from 'react';

function ResetPassword(props) {
  return (
    <div>
      ResetPassword
    </div>
  );
}

export default ResetPassword;
