import React from 'react';
import socketio from 'socket.io-client';

const token = localStorage.getItem('token')
  ? localStorage.getItem('token')
  : false;

export const socket = socketio(
  'http://localhost:4001',
  token && { query: { token } }
); // 147.182.204.50
export const SocketContext = React.createContext();
