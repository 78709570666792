import constants from '../constants/actionTypes';

let initialState = {
  loggedIn: false,
  currentUser: {},
  authError: {},
};

/* eslint import/no-anonymous-default-export: [2, {"allowArrowFunction": true}] */
export default (state = initialState, action) => {
  let updated = Object.assign({}, state);

  switch (action.type) {
    case constants.USER_LOGGEDIN:
      updated['loggedIn'] = true;
      updated['currentUser'] = action.results;
      updated['authError'] = {};
      return updated;

    case constants.GET_CURRENT_USER:
      updated['loggedIn'] = true;
      updated['currentUser'] = action.results;
      updated['authError'] = {};
      return updated;

    case constants.USER_AUTH_ERROR:
      updated['loggedIn'] = false;
      updated['authError'] = action.message;
      return updated;

    case constants.USER_LOGOUT:
      updated['loggedIn'] = false;
      updated['currentUser'] = '';
      return updated;

    default:
      return state;
  }
};
