import React from 'react';
import { useSelector } from 'react-redux';
import { Route, Router, Switch } from 'react-router-dom';
import { history } from './helpers/history';
import { SocketContext, socket } from './context/Socket';

import Layout from './components/Layouts/Layout';
import Home from './components/Home/Home';
import DeviceManagement from './components/DeviceManagement/DeviceManagement';
import Login from './components/Login/Login';
import ResetPassword from './components/Login/ResetPassword';
import ZoomConfig from './components/ZoomConfig';
import ChangePassword from './components/Login/ChangePassword';
import './App.css';

const App = props => {
  const loggedIn = useSelector(state => state.auth.loggedIn);
  const currentUser = useSelector(state => state.auth.currentUser);

  return (
    <Router history={history}>
      <SocketContext.Provider value={socket}>
        <Layout loggedIn={loggedIn} currentUser={currentUser}>
          <Switch>
            <Route exact path="/" component={Home} />
            <Route exact path="/device" component={DeviceManagement} />
            <Route exact path="/access" component={ZoomConfig} />
            <Route exact path="/login" component={Login} />
            <Route exact path="/resetpassword" component={ResetPassword} />
            <Route
              exact
              path="/changepassword/:code"
              component={ChangePassword}
            />
          </Switch>
        </Layout>
      </SocketContext.Provider>
    </Router>
  );
};

export default App;
