import constants from '../constants/actionTypes';

let initialState = {
  users: [],
  userUpdated: false,
  validResetEmail: {},
};

/* eslint import/no-anonymous-default-export: [2, {"allowArrowFunction": true}] */
export default (state = initialState, action) => {
  let updated = Object.assign({}, state);

  switch (action.type) {
    case constants.GET_USERS:
      updated['users'] = action.results;
      return updated;

    case constants.UPDATE_USER:
      updated['user'] = action.results;
      return updated;

    case constants.UPDATE_USER_SETTINGS:
      updated['userUpdated'] = action.results;
      return updated;

    case constants.UPDATE_RESIDENT_INFO:
      updated['residentUpdated'] = action.results;
      return updated;

    case constants.UPDATE_USER_PASSWORD:
      updated['validResetEmail'] = action.results;
      return updated;

    default:
      return state;
  }
};
