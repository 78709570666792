import React from 'react';

function ChangePassword(props) {
  return (
    <div>
      Change password
    </div>
  );
}

export default ChangePassword;
